/*
/* Created by Filipe Pina
 * Specific styles of signin, register, component
 */
/*
 * General styles
 */

body,
html {
	height: 100%;
	background-repeat: no-repeat;
	background-color: #d3d3d3;
	font-family: 'Oxygen', sans-serif;
}

.main {
	margin-top: 70px;
}

h1.title {
	font-size: 50px;
	font-family: 'Passion One', cursive;
	font-weight: 400;
}

hr {
	width: 10%;
	color: #fff;
}

/* custom.css */
.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 20px;
}

.login-box {
	width: 100%;
	max-width: 400px;
	/* Set max-width to the average cellphone screen width */
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.form-group {
	margin-bottom: 15px;
}

label {
	margin-bottom: 15px;
}

input,
input::-webkit-input-placeholder {
	font-size: 11px;
	padding-top: 3px;
}

.main-login {
	background-color: #fff;
	/* shadows and rounded borders */
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

}

.main-center {
	margin-top: 30px;
	margin: 0 auto;
	max-width: 330px;
	padding: 40px 40px;

}

.login-register {
	font-size: 11px;
	text-align: center;
}

.div-padding {
	padding: 15px;
}

.list-group.list-group-root {
	padding: 0;
	overflow: hidden;
}

.list-group.list-group-root .list-group {
	margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
	border-radius: 0;
	border-width: 1px 0 0 0;
}

.list-group.list-group-root>.list-group-item:first-child {
	border-top-width: 0;
}

.list-group.list-group-root>.list-group>.list-group-item {
	padding-left: 30px;
}

.list-group.list-group-root>.list-group>.list-group>.list-group-item {
	padding-left: 45px;
}

.list-group-item .glyphicon {
	margin-right: 5px;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.estate-header {
	margin-bottom: 20px;
	margin-left: 2px;
}

.form-control .comment {
	margin-top: 10px;
}

.loader {
	margin-left: 27%;
}

.loader-text {
	margin-top: 5px;
}

.loader-container {
	width: 330px;
}

.lds-dual-ring {
	display: inline-block;
	width: 136px;
	height: 136px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 128px;
	height: 128px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #19cc6e;
	border-color: #19cc6e transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.has-reading {
	color: lightgreen;
}

.multi-value-wrapper {
	margin-top: 20px;
}

.multi-value>label {
	margin-left: 10px;
	margin-right: 10px;
	font-size: 12px;
	padding-top: 5px;
}

.multi-value>input {
	margin-right: 10px;
	width: auto;
	max-width: 140px;
	float: right;
}

.from-air {
	background-color: rgba(255, 188, 0, 0.2);
	color: #333;
}

.off-air {
	background-color: rgba(136, 59, 0, 0.2);
	color: #333;
}

.out-air {
	background-color: rgba(0, 93, 189, 0.2);
	color: #333;
}

.error-air {
	background-color: rgb(217, 83, 79) !important;
	color: #333;
}

.saveup-tree-icon {
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: scale(1.5);
	width: 30px;
	height: 30px;
	margin-right: 5px;
	float: left;
}

.efficiency {
	background-image: url('../img/SaveUp_Ikon-effektivitet-varmevaxlare.svg') !important;
}

.electricity {
	background-image: url('../img/SaveUp_Ikon-el-ovrigt.svg') !important;
}

.electricity-heat {
	background-image: url('../img/SaveUp_Ikon-elvarme.svg') !important;
}

.cooling-energy {
	background-image: url('../img/SaveUp_Ikon-fjarrkyla-energi.svg') !important;
}

.cooling-flow {
	background-image: url('../img/SaveUp_Ikon-fjarrkyla-flode.svg') !important;
}

.heating-energy {
	background-image: url('../img/SaveUp_Ikon-fjarrvarme-energi.svg') !important;
}

.heating-flow {
	background-image: url('../img/SaveUp_Ikon-fjarrvarme-flode.svg') !important;
}

.time {
	background-image: url('../img/SaveUp_Ikon-gangtid.svg') !important;
}

.oil {
	background-image: url('../img/SaveUp_Ikon-olja.svg') !important;
}

.temperature {
	background-image: url('../img/SaveUp_Ikon-temperatur.svg') !important;
}

.water {
	background-image: url('../img/SaveUp_Ikon-vatten.svg') !important;
}

.saveup-logo {
	max-width: 270px;
}
/* custom.css */
.general-container, .login-container, .estate-container, .save-counter-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%; /* Adjust height to account for footer height */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margin */
  }
  
  .general-box, .login-box, .estate-box, .save-counter-box {
	width: 90%;
	max-width: 400px; /* Set max-width to the average cellphone screen width */
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
  }
  
  .footer-box {
	width: 100%;
	max-width: 400px; /* Set max-width to the average cellphone screen width */
	padding: 8px;
	text-align: "center";
  }

  .form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem; /* Add spacing between form elements */
  }
  
  .logo {
	width: 150px;
	margin-bottom: 1rem;
  }
  
  .image-logo {
	padding-bottom: 50px;
  }
  
  @media (max-width: 600px) {
	.image-logo {
	  padding-bottom: 20px;
	}
  }
  
  .button-primary {
	background-color: #19cc6e !important; /* Login button background color */
	color: #fff;
  }
  
  .button-primary:hover {
	background-color: #17b65e !important; /* Darker shade for hover effect */
  }

  .button-secondary {
	color: black !important; 
	background-color: #C7F7EB !important; 
  }
  
  .button-secondary:hover {
	color: black !important; 
	background-color:#8CEBBF !important; 
  }


  .button-cloud {
	background-color: '#007fff' !important;
	color: 'white'
  }


  .layout-background {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: url('../img/bg.svg') no-repeat center center fixed;
	background-size: cover;
	width: 100%; /* Ensure full width without quotes */
	margin: 0; /* Remove any default margin */
	padding: 0; /* Remove any default padding */
  }
  
  .layout-background main {
	padding: 16px; /* Default padding for larger screens */
	margin: auto; /* Center the box */
	overflow-x: scroll;
  }
  
  @media (max-width: 600px) {
	.layout-background main {
	  padding: 16px; /* Reduced padding for mobile devices */
	}
  }